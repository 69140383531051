import { h } from 'preact'
import { Route, Router } from 'preact-router'

import Home from '../routes/home'
import Impressum from '../routes/impressum'

const App = () => (
	<div id="app">
        <Router>
            <Route path="/" component={Home} />
            <Route path="/impressum" component={Impressum} />
        </Router>
    </div>
)

export default App
